(() => {
  if (window.location.protocol === 'file:') {
    const newBody = document.createElement("body");
    newBody.innerHTML = `
    <style>
        #local-execution-dialog {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);

            margin: 0 auto;
            padding: 16px 24px;

            max-width: 50vw;
            min-width: 50px;

            border-radius: 4px;
            box-shadow: 0 0 10px 0 rgba(0,0,0,0.75);
        }
    </style>

    <div id="local-execution-dialog">
      <p class="mat-body-1">Es scheint so als haben Sie versucht die Anwendung abzuspeichern. Das ist leider nicht möglich.</p>
      <p class="mat-body-1">Bitte benutzen Sie die Online Version <a href="https://video.redmedical.de">https://video.redmedical.de</a></p>
    </div>
  `;

    document.body = newBody;
  }
})();
